@import "~rfs/scss";
@import "breakpoints";
@import "fonts";
@import "palette";

.headline {
	display: flex;
	flex-direction: column;
	padding: 0 20px;
	position: relative;

	.content {
		position: relative;
		margin-bottom: 2rem;

		&:after {
			background-color: inherit;
			content: "";
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			width: 16px;
		}

		&.line--color-burnt-yellow::after {
			background-color: $burnt-yellow;
		}
		&.line--color-green::after {
			background-color: $green;
		}
		&.line--color-blue::after {
			background-color: $blue;
		}
	}

	&.justHeadline {
		padding: 0;
		.content h1 {
			margin-bottom: 0;
		}
	}
}

.headline h1 span {
	@include font-size(78px);
	display: block;
	font-family: $font-noto;
}

.headline {
	h4 {
		@include font-size(20px);
		font-family: $font-lato;
		font-weight: 600;
		margin-bottom: 0.5rem;
		text-align: left;
		text-transform: uppercase;
	}

	.content {
		padding-left: 30px;
		h1 {
			margin-bottom: 1rem;
		}
	}
}

@media (min-width: $medium-device) {
	.headline {
		margin: 0 35px 0 0;
		padding: 0 3rem;

		h1 span,
		h1 {
			@include font-size(60px);
		}

		.content {
			margin-bottom: 1.5rem;
			padding-left: 30px;
		}
	}
}

@media (width: 1091px) {
	.headline .content {
		width: 50%;
	}
}

@media (min-width: $xl-device) {
	.headline {
		margin: 0 35px;
		position: relative;
		width: 50%;
		z-index: 99;

		h1 {
			margin-bottom: 1.5rem;
		}

		&.justHeadline {
			height: max-content;
			position: relative;

			h1 {
				padding: 1rem 0;
			}
		}

		&:before {
			height: 100%;
		}
	}

	.headline .content {
		margin: 0 48px 2.5rem;

		&:after {
			left: -48px;
			top: 0;
		}
	}
}

@media (min-width: $xxl-device) {
	.headline {
		padding: unset;

		.content {
			padding: unset;
			h1 {
				margin: 0 0 1rem 0;
			}
		}
	}
}
