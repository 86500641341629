@import "~rfs/scss";
@import "breakpoints";
@import "palette";

.section-block {
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: center;
	margin-bottom: 1em;
	position: relative;
	text-align: center;

	h1,
	span {
		@include font-size(42px);
		position: relative;
		margin-bottom: 1em;
		z-index: 1;
	}

	h1 > span {
		background: linear-gradient($burnt-yellow, $burnt-yellow) bottom repeat-x;
		background-position: 0 80%;
		background-size: 12px 12px;
	}

	// > div.section-bg-image {
	// 	display: none;
	// }

	@media (min-width: $large-device) {
		height: 464px;
		margin-bottom: 0;

		> div.section-bg-image {
			opacity: 100%;
		}
	}
}

.section-block ~ .section-block {
	margin-bottom: 0;
}

.section-block .section-content-image {
	height: 100%;
	position: relative;
	width: 100%;
}

.section-block.section-block-columns {
	flex-direction: column;
	// height: 100vh;
	margin-bottom: 2em;

	@media (min-width: $medium-device) {
		flex-direction: row;

		.section-content-image {
			height: unset;
		}
	}

	@media (min-width: $xl-device) {
		margin-bottom: 5em;
	}

	.section-content {
		background-color: #ececed;
		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: center;
		text-align: left;
		width: 100%;
		max-width: unset;
		position: relative;

		p {
			font-size: 18px;
		}

		> div {
			margin: 0 auto;
			max-width: 650px;
		}
		&-default {
			width: 50%;
		}

		@media (min-width: $medium-device) {
			height: 100%;
		}

		&.bg-transparent {
			background-color: transparent;
		}
	}
}

.section-block .section-content {
	max-width: 920px;
	padding: 2em;
	z-index: 0;

	@media (min-width: $medium-device) {
		padding: 4em;
	}

	@media (min-width: $xl-device) {
		padding: 6em;
	}
}

.section-block.section-block-image-only {
	flex-direction: row;
	height: 30vh;
	position: relative;

	@media (min-width: $xl-device) {
		height: 464px;
	}
}

.section-block .section-content-stats {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin: 0 auto;
	max-width: 960px;
	width: 100%;

	.stat {
		font-family: "helvetica";
		margin-bottom: 3em;

		p {
			line-height: 1;
		}

		p + p {
			@include font-size(18px);
		}
		> p {
			@include font-size(80px);
			display: flex;
			font-weight: bold;
			justify-content: center;
		}
	}

	@media (min-width: $medium-device) {
		flex-direction: row;
	}

	@media (min-width: $large-device) {
		.stat {
			width: 33%;
		}
	}
}
