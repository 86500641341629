@import "breakpoints";

.contact-footer {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	padding: 0 1rem;

	.contact-headline {
		margin-bottom: 1em;
		padding: unset;

		> div {
			&:after {
				left: 0;
			}
		}
	}
	&.contact-footer-columns {
		flex-direction: column;
		padding: 0;

		form {
			padding: 1em;
		}

		@media (min-width: $medium-device) {
			flex-direction: row;

			form {
				padding: 2em;
			}
		}

		@media (min-width: $large-device) {
			form {
				padding: 5em 8em;
				width: 70&;
			}
		}
	}
}

.contact-image {
	height: auto;
	position: relative;
	width: 100%;
}

@media (min-width: $medium-device) {
	.contact-footer {
		flex-direction: row;
	}

	.contact-headline {
		> div {
			margin-left: 0 !important;
		}
	}
}

@media (min-width: $xl-device) {
	.contact-footer {
		padding: 0 4rem;

		.contact-headline {
			width: 38%;
		}
	}
}

@media (min-width: $xxl-device) {
	.contact-headline {
		margin: unset;

		> div {
			h1 {
				margin-left: 48px !important;

				padding: unset;
			}
		}
	}
}
