@import "breakpoints";
@import "palette";

.carousel {
	position: relative;

	@media (min-width: $xl-device) {
		margin-bottom: 6em;
	}

	@media (min-width: 1920px) {
		margin-bottom: 6em;
	}
}

.embla {
	position: relative;
}

.embla__viewport {
	overflow: hidden;
	width: 100%;
}

.embla__viewport.is-draggable {
	cursor: move;
	cursor: grab;
}

.embla__viewport.is-dragging {
	cursor: grabbing;
}

.embla__container {
	display: flex;
	user-select: none;
	-webkit-touch-callout: none;
	-khtml-user-select: none;
	-webkit-tap-highlight-color: transparent;
}

.embla__slide {
	display: flex;
	flex: 0 0 100%;
	position: relative;
	max-width: 100%;
}

.embla__slide__inner {
	position: relative;
	overflow: hidden;
	height: 464px;
	width: 100%;
}

.embla__slide__img {
	height: 100%;
	object-fit: cover;
	width: 100%;
}

.embla__button {
	display: none;
	color: #fff;
	outline: 0;
	cursor: pointer;
	background-color: $navy;
	touch-action: manipulation;
	position: absolute;
	z-index: 1;
	top: 50%;
	transform: translateY(-50%);
	border: 0;
	justify-content: center;
	align-items: center;
	padding: 0;

	> svg {
		width: 50%;
	}

	@media (min-width: $medium-device) {
		display: block;
		width: 72px;
		height: 72px;
	}
}

.embla__button:disabled {
	cursor: default;
	opacity: 0.3;
}

.embla__button__svg {
	width: 100%;
	height: 100%;
}

.embla__button--prev {
	left: 0;
}

.embla__button--next {
	right: 0;
}

.embla__dots {
	bottom: 1em;
	display: flex;
	list-style: none;
	left: 50%;
	justify-content: center;
	padding-top: 10px;
	position: absolute;
}

.embla__dot {
	background-color: transparent;
	cursor: pointer;
	position: relative;
	padding: 0;
	outline: 0;
	border: 0;
	width: 30px;
	height: 30px;
	margin-right: 7.5px;
	margin-left: 7.5px;
	display: flex;
	align-items: center;
}

.embla__dot:after {
	background-color: #fff;
	width: 100%;
	height: 4px;
	border-radius: 2px;
	content: "";
}

.embla__dot.is-selected:after {
	background-color: $black;
	opacity: 1;
}
