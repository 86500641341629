@import "~rfs/scss";
@import "breakpoints";
@import "fonts";
@import "palette";

@mixin button($mode: false) {
	@include font-size(16px);
	background: $black;
	border: 3px solid transparent;
	border-radius: 40px;
	color: $white;
	cursor: pointer;
	display: flex;
	font-family: $font-lato;
	font-weight: bold;
	line-height: 1;
	justify-content: center;
	padding: 18px 0;
	position: relative;
	text-decoration: none;
	text-transform: uppercase;
	width: 100%;

	@if $mode == secondary {
		border-color: $white;
	}

	@media (min-width: $medium-device) {
		width: 280px;
	}
}
