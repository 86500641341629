@import "breakpoints";

.logo-sections {
	section {
		display: flex;
		flex-direction: column;
		margin-bottom: 3rem;

		text-align: center;

		h2 {
			margin-bottom: 1.5rem;
		}

		ul {
			li {
				display: inline-block;
			}
		}

		.grayscale {
			filter: grayscale(100%);
		}

		.logo-img {
			aspect-ratio: 4/3;
			height: 176px;
			object-fit: contain;
		}
	}
}

@media (min-width: $large-device) {
	.logo-sections {
		section {
			margin-bottom: 3rem;
			> div {
				margin: 0 auto;
				max-width: 875px;
			}
		}
	}
}
