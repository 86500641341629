@import "breakpoints";

.hero {
	margin-bottom: 3rem;
	width: 100%;

	h1 {
		font-weight: 400;
		grid-area: h1;
		letter-spacing: 0;
	}
}

@media (min-width: $large-device) {
	.hero {
		display: flex;
		height: 12rem;
		margin-bottom: 0;

		.hero-image {
			left: 50%;
			position: absolute;
			top: 0;
		}

		div + div {
			max-width: 545px;
		}
	}
}

@media (min-width: $xxl-device) {
	.hero {
		height: 13rem;
	}
}

@media (min-width: 1920px) {
	.hero {
		margin-bottom: 10rem;
	}
}
