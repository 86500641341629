@import "breakpoints";
@import "button";
@import "palette";

.blurb {
	background-color: $black;
	color: $white;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-bottom: 25px;
	padding: 40px 20px;
	position: relative;

	h2 {
		@include font-size(42px);
		font-weight: normal;
		margin-bottom: 3rem;
	}

	p {
		@include font-size(18px);
	}

	.footnote {
		h3 {
			@include font-size(14px);
			font-family: $font-lato;
		}
		text-align: right;
	}
}

.blurb .blurb-body {
	display: flex;
	flex-direction: column;
	max-width: 940px;
	margin: 0 auto;
}

.btn > p a {
	@include button($mode: secondary);
	font-size: 1rem;
}

.blurb .list {
	columns: 1;

	li {
		@include font-size(24px);
		font-weight: bold;
		margin-bottom: 1.5rem;
		text-transform: uppercase;

		&:before {
			background-color: $white;
			content: "";
			display: block;
			height: 2px;
			margin-bottom: 1.5rem;
			position: relative;
			width: 116px;
		}
	}
}

@media (min-width: $medium-device) {
	.blurb {
		margin-bottom: 5rem;
		padding: 40px;

		.list {
			columns: 2;

			li {
				margin-bottom: 3rem;
			}
		}
	}
	.blurb h2 {
		font-size: 2rem;
		margin-bottom: 1rem;
	}

	.blurb h3 {
		margin-bottom: 1rem;
	}

	.about-blurb-image {
		width: 40%;
	}
}

@media (min-width: $xl-device) {
	.blurb {
		margin-bottom: 150px;
		padding: 5rem 0;

		&.align-left {
			border-top-right-radius: 340px;
			border-bottom-right-radius: 340px;
			left: 0;
			margin-right: 10%;
		}
		&.align-right {
			align-items: end;
			border-top-left-radius: 340px;
			border-bottom-left-radius: 340px;
			margin-left: 10%;
			right: 0;

			.blurb-content {
				flex-direction: row-reverse;
				text-align: right;
			}

			p {
				text-align: right;
			}
		}

		.about-blurb-image {
			width: 100%;
		}
	}

	.blurb .blurb-content {
		display: flex;
	}

	.blurb .footnote h3 {
		@include font-size(16px);
	}

	.blurb .blurb-body {
		&.services-body {
			max-width: unset;
			width: 1200px;
		}

		div p:last-of-type {
			margin-bottom: 2.5rem;
		}
	}
}
