@import "breakpoints";
@import "fonts";
@import "palette";
@import "~rfs/scss";

.tpg-form {
	display: inline-block;
	width: 100%;

	div {
		margin-bottom: 0.5rem;
	}

	input {
		@include font-size(20px);
		appearance: none;
		border: 2px solid $black;
		border-radius: 35px;
		display: block;
		padding: 14px;
		width: 100%;

		&::placeholder {
			@include font-size(20px);
			color: $black;
			font-family: $font-lato;
			font-weight: bold;
		}
	}

	.error {
		color: $red;
		font-size: 14px;
		padding: 0 16px;
	}

	.policy {
		@include font-size(14px);
		padding: 0 18px;
		width: 100%;
	}
}

.tpg-form legend {
	@include font-size(42px);
	font-family: $font-noto;
	margin-bottom: 1em;

	h1,
	span {
		font-size: inherit;
		font-family: inherit;
		margin-bottom: inherit;
	}

	h1 > span {
		background: linear-gradient($burnt-yellow, $burnt-yellow) bottom repeat-x;
		background-position: 0 80%;
		background-size: 12px 12px;
	}
}

@media (min-width: $large-device) {
	.tpg-form .policy {
		line-height: 1;
	}

	.contact-image {
		position: relative;
		width: 100%;
	}
}
