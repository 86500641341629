$black: #192131;
$blue: #6d8a96;
$brown: #291e1c;
$burnt-yellow: #e6a737;
$green: #607466;
$off-white: #f3f3f4;
$red: #990000;
$secondary-black: #1a2130;
$white: #fff;
$yellow: #dda94e;
$navy: #2f3745;
