@import "~rfs/scss";
@import "breakpoints";
@import "fonts";
@import "palette";

.card {
	grid-template-areas:
		"cardImage"
		"cardContent";
	grid-template-columns: 1fr;
	position: relative;

	.card-image {
		display: block;
		grid-area: cardImage;
		height: 140px;
		position: relative;
		width: 100%;
	}

	.card-body {
		margin-bottom: 2rem;
		width: 100% !important;

		p {
			font-size: 18px;
			margin-bottom: 15px;
		}
	}
}

.card h2 {
	@include font-size(42px);
	font-family: $font-noto;
	margin-bottom: 2rem;
	position: relative;
	z-index: 9;

	&.inline {
		display: inline-block;
	}
	&.block {
		display: block;
	}

	&:after {
		background: transparent;
		content: "";
		display: inline-block;
		height: 15px;
		left: -5px;
		position: absolute;
		top: 18px;
		width: 105%;
		z-index: -1;
	}
	&.underline-burnt-yellow::after {
		background: $burnt-yellow;
	}
	&.underline-green::after {
		background: $green;
	}
}

@media (min-width: $medium-device) {
	.card h2 {
		&:after {
			top: 21px;
		}
	}
}

@media (min-width: $large-device) {
	.card h2::after {
		top: 30px;
	}
}

.card .card-link {
	@include font-size(20px);
	border-bottom: 3px solid $red;
	color: $black;
	font-family: $font-lato;
	font-weight: bold;
	text-decoration: none;
	padding-bottom: 0.3rem;
}

@media (min-width: $xxl-device) {
	.card .card-image {
		height: 296px;
		margin-bottom: 3rem;
	}
}
