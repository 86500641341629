@import "~rfs/scss";
@import "breakpoints";
@import "fonts";
@import "palette";

.banner {
	display: flex;
	flex-direction: column;
	height: 555px;
	justify-content: center;
	margin-top: -13.2em;
	overflow: hidden;
	position: relative;
	text-align: center;
	width: 100%;

	.banner-content {
		align-items: center;
		display: flex;
		height: 100%;
		flex-direction: column;
		justify-content: center;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: 0;

		video {
			min-height: 83%;
			min-width: 100%;
			height: 83vh;
			left: 0;
			object-fit: cover;
			position: absolute;
			top: 0;
			width: 100vw;
		}

		.banner-image {
			display: block;
			margin-bottom: 3em;
			width: 50%;

			@media (min-width: $large-device) {
				width: 27%;
			}
		}

		h1 > span {
			@include font-size(33px);
			background: linear-gradient($burnt-yellow, $burnt-yellow) bottom repeat-x;
			background-position: 0 80%;
			background-size: 12px 12px;
			font-family: $font-noto;
			position: relative;
			z-index: 1;

			@media (min-width: $small-device) {
				background-size: 12px 12px;
			}

			@media (min-width: $large-device) {
				letter-spacing: -1px;
				font-size: 2.4rem;
			}
		}
	}

	@media (min-width: $large-device) {
		height: 750px;
		margin-top: -15.2em;
	}
}
