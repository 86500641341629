@import "button";
@import "palette";

.btn {
	@include button;
	color: $white !important;
	padding: 18px;

	&.outline {
		background: transparent;
		border: 3px solid $black;
		color: $black;
	}
}
