@import "breakpoints";

.card-list {
	display: grid;
	gap: 52px;
	grid-template-areas:
		"card"
		"card";
	margin-bottom: 3rem;
	padding: 0 24px;
	position: relative;
}

@media (min-width: $medium-device) {
	.card-list {
		grid-template-areas: "card card";
	}
}

@media (min-width: $large-device) {
	.card-list {
		grid-template-areas: "card card card";
		margin-bottom: 7rem;
	}
}
