@import "~rfs/scss";
@import "palette";

.footer {
	align-items: center;
	background-color: $black;
	display: flex;
	flex-flow: column;
	justify-content: center;
	padding: 4rem 0;
	text-align: center;
	width: 100%;

	p,
	a {
		@include font-size(16px);
		color: $white;
		font-weight: bold;
	}
	a {
		text-decoration: none;
	}

	span a {
		padding: 10px;
	}
}

.footer .footer-content-group {
	align-items: center;
	border-bottom: 1px solid #fff;
	display: flex;
	margin-bottom: 2em;
	justify-content: space-between;

	div {
		padding: 0 1em;
	}
}

.footer .footer-content {
	flex-direction: row;
}

.footer .footer-content-links {
	display: flex;
	flex-direction: column;
	justify-items: center;
	a {
		display: block;
		text-align: left;
	}
}
