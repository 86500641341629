@import "breakpoints";
@import "fonts";
@import "palette";
@import "~rfs/scss";

$hb-height: 27px;
$hb-width: 35px;

.header {
	margin: 1rem auto 4rem;
	position: relative;
	z-index: 1;

	> * {
		place-self: flex-start;
	}

	.logo {
		display: flex;
		grid-area: logo;
		place-self: start;
		width: 83px;
	}

	.nav {
		align-self: center;
		display: flex;
		justify-content: space-between;
		margin-top: 2em;
		position: relative;
	}
}

.nav .desktop {
	display: none;
}

.header .nav a {
	align-items: center;
	color: $black;
	display: flex;
	font-family: $font-lato;
	@include font-size(16px);
	font-weight: bold;
	letter-spacing: 0.72px;
	position: relative;
	text-decoration: none;
	text-transform: uppercase;

	&.activeLink {
		background: linear-gradient($burnt-yellow, $burnt-yellow) bottom repeat-x;
		background-position: 0 90%;
		background-size: 12px 5px;
		font-family: $font-noto;
		position: relative;
		z-index: 1;
	}
}

.header .hb-menu {
	cursor: pointer;
	height: $hb-height;
	position: fixed;
	right: 8%;
	transition: opacity 0.25s ease;
	width: $hb-width;
	z-index: 100;

	&:hover {
		opacity: 0.7;
	}
	&.active {
		.top {
			transform: translateY(11px) translateX(0) rotate(45deg);
			background: $white;
		}
		.middle {
			opacity: 0;
			background: $white;
		}

		.bottom {
			transform: translateY(-11px) translateX(0) rotate(-45deg);
			background: $white;
		}
	}

	span {
		background: $black;
		border: none;
		cursor: pointer;
		height: 5px;
		left: 0;
		position: absolute;
		top: 0;
		transition: all 0.35s ease;
		width: 100%;

		&:nth-of-type(2) {
			top: 11px;
		}
		&:nth-of-type(3) {
			top: 22px;
		}
	}
}

.header .header-hero-image {
	position: absolute;
	right: 0;
	top: 0;
}

.overlay {
	background: rgba(230, 167, 55, 1);
	height: 0%;
	left: 0;
	opacity: 0;
	overflow: hidden;
	position: fixed;
	top: 0;
	transition: opacity 0.35s, visibility 0.35s, height 0.35s;
	visibility: hidden;
	width: 100%;

	&.open {
		background: rgba(230, 167, 55, 0.9);
		height: 100%;
		opacity: 1;
		visibility: visible;

		li {
			animation: fadeInRight 0.5s ease forwards;
			animation-delay: 0.35s;

			&:nth-of-type(2) {
				animation-delay: 0.4s;
			}
			&:nth-of-type(3) {
				animation-delay: 0.45s;
			}
			&:nth-of-type(4) {
				animation-delay: 0.5s;
			}
		}
	}
}

.nav .mobile {
	@include font-size(24px);
	display: inline-flex;
	flex-direction: column;
	height: 100%;
	justify-content: center;
	list-style: none;
	margin: 0 auto;
	padding: 0;
	position: relative;
	text-align: center;
	top: 50%;
	transform: translateY(-50%);
	width: 100%;

	li {
		display: block;
		min-height: 50px;
		opacity: 0;
		position: relative;

		a {
			@include font-size(32px);
			color: $white;
			display: block;
			overflow: hidden;
			position: relative;
			text-decoration: none;

			&:hover:after,
			&:focus:after,
			&:active:after {
				width: 100%;
			}
		}
	}
}

@media (min-width: $medium-device) {
	.header .logo {
		width: 150px;
	}

	.header {
		margin-bottom: 4em;
	}

	.nav .desktop a[class*="btn"] {
		width: 200px !important;
	}
}

@media (min-width: $large-device) {
	.nav {
		.hb-menu,
		.mobile {
			display: none;
		}
		.desktop {
			grid-area: nav;
			display: grid;
			gap: 3em;
			grid-auto-flow: column;
			grid-auto-columns: max-content;
			height: 100%;
			place-self: center;
		}
	}
	.header {
		grid-template-columns: none;
		padding: unset;

		> * {
			place-self: flex-end;
		}
	}

	.nav .desktop {
		li {
			display: flex;
		}
	}
}

@media (min-width: $xxl-device) {
	.header {
		margin-bottom: 7rem;
	}
}

@keyframes fadeInRight {
	0% {
		opacity: 0;
		left: 20%;
	}
	100% {
		opacity: 1;
		left: 0;
	}
}
